export class AnagraficaDistributoreLight {
	constructor() {
		this.itemID = null;
		this.ragioneSociale = null;
	}
	itemID: number;
	ragioneSociale: string;
}

export class AnagraficaDistributore {
	/**
	 *
	 */
	constructor() {
		this.itemID = null;
		this.itemGUID = null;
		this.ragioneSociale = null;
		this.email = null;
		this.telefono = null;
		this.fax = null;
		this.tipoDistributoreID = null;
		this.distributorePadreID = null;
		this.codicePagamentoID = null;
		this.indirizzo = null;
		this.localita = null;
		this.codiceProvincia = null;
		this.cAP = null;
		this.commissioni = null;
		this.email2 = null;
		this.email3 = null;
		this.partitaIva = null;
		this.linguaID = null;
		this.cancellato = null;
		this.wI_ID = null;
		this.codiceFiscale = null;
		this.pagamentoLimiteCredito = null;
		this.mailInviaFileSeparati = null;
		this.gruppoID = null;
		this.telefono2 = null;
		this.telefono3 = null;
		this.calcoloECInizioViaggio = null;
		this.bannerHPInterna = null;
		this.note = null;
		this.importConfiguration = null;
		this.istanteInserimento = null;
		this.istanteModifica = null;
		this.provincia = null;
		this.regione = null;
		this.tipoDistributore = null;
		this.controlloIddEscluso = null;
	}
	itemID: number;
	itemGUID = null;
	ragioneSociale = null;
	email = null;
	telefono = null;
	fax = null;
	tipoDistributoreID = null;
	distributorePadreID = null;
	codicePagamentoID = null;
	indirizzo = null;
	localita = null;
	codiceProvincia = null;
	cAP = null;
	commissioni = null;
	email2 = null;
	email3 = null;
	partitaIva = null;
	linguaID = null;
	cancellato = null;
	wI_ID = null;
	codiceFiscale = null;
	pagamentoLimiteCredito = null;
	mailInviaFileSeparati = null;
	gruppoID = null;
	telefono2 = null;
	telefono3 = null;
	calcoloECInizioViaggio = null;
	bannerHPInterna = null;
	note = null;
	importConfiguration = null;
	istanteInserimento = null;
	istanteModifica = null;
	provincia = null;
	regione = null;
	tipoDistributore = null;
	controlloIddEscluso: boolean;
}

export class AnagraficaDistributoreEdit extends AnagraficaDistributore {
	/**
	 *
	 */
	constructor() {
		super();
		this.bannerHPInterna_file = null;

	}
	bannerHPInterna_file: File;
}